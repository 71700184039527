// Conclusion.tsx
import React, { useState, useEffect, useRef } from "react";
import "./css/thinking.css";
import "./css/conclusion.css";
import { QuestionData } from "../types";
const ThinkingMan = require("../assets/TænkePreben1.png");


type PrebenConclusionsType = {
  PytPreben: { text: string; img: string; };
  PerfektionistPreben: { text: string; img: string; };
  PræstationsPreben: { text: string; img: string; };
  PligtPreben: { text: string; img: string; };
  [key: string]: { text: string; img: string; };
};

const PrebenConclusions: PrebenConclusionsType = {
  PytPreben: {
    text: "Baseret på dine svar ser det ud til, at du har en Pyt-Preben personlighedstype. Du håndterer livets udfordringer med ro og positivitet. Din tilgang er afslappet, og du fokuserer på det positive i selv stressede situationer. Kreativitet og spontanitet er vigtige værdier for dig, og du ser fejl som en naturlig del af livet, der kan løses med en positiv indstilling.",
    img: "assets/images/PrebenTrophe1.svg",
  },
  PerfektionistPreben: {
    text: "Efter at have analyseret dine svar, ser det ud til, at du har en Perfektionist-Preben personlighedstype. Du stræber efter perfektion og har en grundig tilgang til dine opgaver. Fejl og mangler irriterer dig, og du arbejder hårdt for at opnå høje standarder. Dine præstationsmål er vigtige, og du er ikke bange for at tage ansvar for dine handlinger.",
    img: "assets/images/PrebenTrophe1.svg",
  },
  PræstationsPreben: {
    text: "Dine svar antyder, at din personlighedstype er Præstations-Preben. Du er ambitiøs, fokuseret og sætter høje standarder for dig selv. Du ser udfordringer som muligheder for at vise dine evner og præstere på et højt niveau. At opnå succes er vigtigt for dig, og du arbejder hårdt for at nå dine mål.",
    img: "assets/images/PrebenTrophe1.svg",
  },
  PligtPreben: {
    text: "Ifølge dine svar er din personlighedstype Pligt-Preben. Du er pålidelig, ansvarlig og prioriterer dine forpligtelser. At opfylde dine pligter og gøre det rigtige er vigtige værdier for dig. Du kan være tilbageholdende med konfrontation, men du tager ansvar for dine handlinger og søger løsninger, der opfylder dine forpligtelser.",
    img: "assets/images/PrebenTrophe1.svg",
  },
};


interface ConclusionProps {
  userAnswers: string[];
  questionId: string;
  questions: QuestionData[];
  onStartAgain: () => void;
}

interface Data {
  id: string;
  answer: string[];
  questions: QuestionData[];
  thread_id: string;
  run_id: string;
  retry_count: number;
}

const ThinkingAnimation: React.FC = () => {
  return (
    <div className="thinking-animation">
      <div className="spinner"></div>
      <div className="thinking">
        {/* <TænkePreben/>
         */}
        <img src={ThinkingMan} alt="pic3" className="imageThinking" />
      </div>
      <p className="thinking-text">
        YES! - så fik du svaret på dem alle. Nu sætter jeg dem sammen og tænker
        lidt over det. Vi nærmer os snart en konklusion...
      </p>
    </div>
  );
};

type ApiDataType = {
  id: string; // replace with the actual type of questionId
  answer: any[]; // replace with the actual type of userAnswers
  questions: any[]; // replace with the actual type of questions
  thread_id: string;
  run_id: string;
  retry_count: number;
};

type Imgapath = {
  PytPreben: string;
  PræstationsPreben: string;
  PerfektionistPreben: string;
  PligtPreben: string;
  [key: string]: string;
};

let imgapath: Imgapath = {
  PytPreben: "1",
  PræstationsPreben: "2",
  PerfektionistPreben: "3",
  PligtPreben: "4",
};


const Conclusion: React.FC<ConclusionProps> = ({
  userAnswers,
  questionId,
  questions,
  onStartAgain,
}) => {
  const [conclusionTxt, setResult] = useState<string | null>(null);
  const [winnerTxt, setWinner] = useState<string | null>(null);
  const [winnerSection, setWinnerSection] = useState<string | null>(null);
  const [titleColor, setTitleColor] = useState<string | null>(null);
  const initialized = useRef(false);

  const [showThinkingAnimation, setShowThinkingAnimation] =
    useState<boolean>(true);
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(true);
  };

  const [prebenAmounts, setPrebenAmounts] = useState<{
    PytPreben: number;
    PræstationsPreben: number;
    PerfektionistPreben: number;
    PligtPreben: number;
    [key: string]: number;
  }>({
    PytPreben: 0,
    PræstationsPreben: 0,
    PerfektionistPreben: 0,
    PligtPreben: 0,
  });

  let sortedKeys = Object.keys(prebenAmounts).sort(
    (a, b) => prebenAmounts[b] - prebenAmounts[a]
  );
  if (winnerTxt != null) {
    var temptitle = winnerTxt.replace("-", "");

    if(prebenAmounts.PerfektionistPreben == 2 && prebenAmounts.PræstationsPreben == 2 && prebenAmounts.PytPreben == 1){
    }
    else if (sortedKeys[0] != temptitle) {
      var temp = sortedKeys[0];
      var index = 0;
      for(var i = 0; i < sortedKeys.length; i++){
        if(sortedKeys[i] == temptitle){
          index = i;
        }
      }

      sortedKeys[index] = sortedKeys[0];
      sortedKeys[0] = temptitle;
    }
  }
  console.log("sortedKeys: ", sortedKeys);
  console.log("prebenAmounts: ", prebenAmounts);
  let imagePath = sortedKeys.map((key) => imgapath[key]).join("-");
  // Map the sorted keys to their corresponding values in imgapath and join them with '-'
  let path = "../assets/images/PodiumSVG/" + imagePath + ".svg";
  console.log("path: ", path);

  // Find the key with the highest value
  let maxKey = Object.keys(prebenAmounts).reduce((a, b) =>
    prebenAmounts[a] > prebenAmounts[b] ? a : b
  );
  console.log("maxKey: ", maxKey);

  let finalResultTitle = maxKey;
  // Fetch the corresponding image and text
  // console.log(maxKey);
  // console.log("this is the maximum value for the key: ");

  finalResultTitle = finalResultTitle.split("Preben").join("-Preben");
  console.log("Preben result: "+ finalResultTitle);

  let conclusion = PrebenConclusions[maxKey];
  console.log("conclusion: ", conclusion);

  useEffect(() => {
    // Mimic the population of userAnswers by other functions
    setTimeout(() => {
      // userAnswers = ["PerfektionistPreben", "PræstationsPreben", "PytPreben", "PligtPreben","PytPreben"];
      userAnswers.forEach((answer) => {
        // Remove the hyphen from the answer
        const key = answer.replace("-", "");

        setPrebenAmounts((prevState) => ({
          ...prevState,
          [key]: prevState[key] + 1,
        }));
      });
    }, 2000); // Delay of 2 seconds

    const timer = setTimeout(() => {
      //setShowThinkingAnimation(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const data: ApiDataType = {
      id: questionId,
      answer: userAnswers,
      questions: questions,
      thread_id: "",
      run_id: "",
      retry_count: 0,
    };

    if (!initialized.current) {
      initialized.current = true;

      return;
    }
    initialized.current = false;

    async function handleAPIRequest(apiData: ApiDataType) {
      try {
        const response = await callExternalAPI(apiData);
        // Assuming response is a JSON object with a status field

        if (response.status === "timeout") {
          // Update apiData with new values from the response for retry
          const updatedData: ApiDataType = {
            id: questionId,
            answer: userAnswers,
            questions: questions,
            thread_id: response.thread_id,
            run_id: response.run_id,
            retry_count: response.retry_count,
          };
          // Recursive call to retry with updated data
          await handleAPIRequest(updatedData);
        } else if (response.status === "failure") {
          // Handle failure as in the existing catch block logic
          console.error("API call failed due to failure status.");
          window.scrollTo(0, 0);
          setShowThinkingAnimation(false);
        } else {
          // Handle successful API response
          console.log("API call was successful, proceed with the response:", response);
          // Process your successful response here (e.g., updating state)
          console.log("after callExternalAPI (response):", response);
          window.scrollTo(0, 0);
          setShowThinkingAnimation(false);
          console.log("after callExternalAPI (setResult call)");
          console.log("after callExternalAPI (setResult call) answer:", response.answer);
          setResult(response.answer);
          console.log("after callExternalAPI (setWinner call)");
          console.log("after callExternalAPI (setWinner call) winner:", response.winner);
          setWinner(response.winner);
          console.log("after callExternalAPI (preben_count):", response.preben_count);
          console.log("after callExternalAPI (Pyt-Preben):", response.preben_count["Pyt-Preben"]);
          console.log("after callExternalAPI (Præstations-Preben):", response.preben_count["Præstations-Preben"]);
          console.log("after callExternalAPI (Perfektionist-Preben):", response.preben_count["Perfektionist-Preben"]);
          console.log("after callExternalAPI (Pligt-Preben):", response.preben_count["Pligt-Preben"]);
          const newAmount = {
            PytPreben: response.preben_count["Pyt-Preben"],
            PræstationsPreben: response.preben_count["Præstations-Preben"] == undefined ? 0 : response.preben_count["Præstations-Preben"],
            PerfektionistPreben: response.preben_count["Perfektionist-Preben"],
            PligtPreben: response.preben_count["Pligt-Preben"],
          };
          setPrebenAmounts(newAmount);
          console.log("New amount: ", newAmount);
  
          const title = document.getElementById("winnerTxtId");
          if (response.winner == "Perfektionist-Preben") {
            setTitleColor("#5590C1");
            setWinnerSection("#section-perfektionist");
          } else if (response.winner == "Præstations-Preben") {
            setTitleColor("#C16961");
            setWinnerSection("#section-praestations");
          } else if (response.winner == "Pyt-Preben") {
            setTitleColor("#F0B2B5");
            setWinnerSection("#section-pyt");
          } else if (response.winner == "Pligt-Preben") {
            setTitleColor("#7DBF92");
            setWinnerSection("#section-pligt");
          } else {
            setTitleColor("#5590C1");
            setWinnerSection("#section-pyt");
          }
        }
      } catch (error) {
        // Handle errors in API call
        console.error("An error occurred during the API call:", error);
        window.scrollTo(0, 0);
        setShowThinkingAnimation(false);
        //      setResult("Der var problemer med at få resultatet. Tryk her for at prøve igen")
        // console.error('An error occurred:', error);
      }
    }

    // Initial call to the function with the initial data
    handleAPIRequest(data);


  }, [userAnswers, questionId, questions]); // Empty dependency array// Empty dependency array
  // Empty dependency array

  // callExternalAPI(data);

  return (
    <div className="conclusion-container">
      {showThinkingAnimation ? (
        <ThinkingAnimation />
      ) : (
        <div className="conclusion-content">
          <div>
            <img className="conclusion-img" src={path} alt={maxKey} />
          </div>
          <h2 className="conclusion-title">Du er primært</h2>
          <h2
            id="winnerTxtId"
            className="conclusion-key"
            style={{ color: titleColor! }}
          >
            {winnerTxt}
          </h2>
          <p className="conclusion-text">{conclusionTxt}</p>

          <div>
            <button className="start-again-button" onClick={onStartAgain}>
              Tag en ny test
            </button>
            <button className="preben-type-button" onClick={() => window.open('https://befripreben.dk/hvilken-preben-er-du/' + winnerSection, '_blank')}>Læs om {winnerTxt}</button>
            </div>
        </div>
      )}
      <div id="footer" className="footer" style={{ color: "white" }}>
        <p className="h3">
          © 2024 Lavet med {" "}
          <a
            href="https://rollebot.dk"
            target="_blank"
            style={{ color: "green" }}
          >
            RolleBot
          </a>{" "}
          - udviklet af{" "}
          <a href="https://2ai.dk" target="_blank" style={{ color: "green" }}>
            {" "}
            2ai
          </a>
        </p>
      </div>
    </div>
  );
};

export default Conclusion;
async function callExternalAPI(datas: Data): Promise<any> {
  const updata = JSON.stringify(datas);
  return await fetch(
    "https://preben-api.rollebot.dk/get_conclusion",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: updata,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      if (data !== "") {
        console.log("Success (conclusion API):", data);
        return data;
      }
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
}

